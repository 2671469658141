// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }
body {
  overflow: hidden !important;
}
.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

.login_Logo {
  // style={{width:"500px",height:"60px",background:"white",borderRadius:"24px"}}
  width: 500px;
  height: 140px;
  // background: white;
  border-radius: 24px;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login_Logo img {
  width: 340px;
}
@media (max-width: 576px) {
  .login_Logo {
    // style={{width:"500px",height:"60px",background:"white",borderRadius:"24px"}}
    width: 320px;
    height: 140px;
    background: white;
    border-radius: 24px;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login_Logo img {
    border-radius: 0px !important;
    width: 180px;
    height: 52px;
  }
}

@media (max-width: 414px) {
  .login_Logo {
    // style={{width:"500px",height:"60px",background:"white",borderRadius:"24px"}}
    width: 320px;
    height: 90px;
    background: white;
    border-radius: 24px;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login_Logo img {
    width: 180px;
    height: 52px;
  }
}

.form_logo {
  width: 200px;
  height: auto;
}
.login_form_top {
  margin-top: auto;
}
@media only screen and (max-width: 576px) {
  .login_form_top {
    margin-top: unset !important;
  }
  .form_logo {
    width: 160px;
    height: 60px;
  }
}
.aside-menu .menu-nav > .menu-item > .menu-link {
  border-radius: 12px !important;
}
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
  background-color: #010066;
  border-radius: 12px !important;
}
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-icon {
  color: #2ab3d4 !important;
}
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text {
  color: #2ab3d4 !important ;
}
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link {
  background: #010066 !important;
}
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover {
  background-color: none;
  transition: none !important;
  background: none;
  * {
    background-color: none;
    transition: none !important;
    background: none;
  }
}
.quill {
  height: 340px;
  margin: 40px 0px;
}
.custom-select__menu {
  z-index: 999999;
}
.modal-body {
  overflow-y: unset !important;
}
.MuiTypography-displayBlock {
  white-space: pre-line !important;
}
.MuiCardHeader-title {
  white-space: pre-line !important;
}

.MuiPaper-root {
  border-radius: 16px !important;
}
h1 {
  color: #010066 !important;
  font-weight: 500 !important;
  font-size: 28px;
}
.word-break-text {
  word-break: break-all !important;
}
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading
  .menu-icon,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link
  .menu-icon {
  color: #2ab3d4 !important;
}
.Text_content {
  height: fit-content !important;
}
.Date_Picker_Wrapper {
  .MuiFormControl-root {
    width: 100% !important;
  }
}
.rc-time-picker {
  width: 100% !important;
}
.rc-time-picker-input {
}
.Edit_email_hour {
  .rc-time-picker-clear {
    display: none !important;
  }
  .rc-time-picker-clear-icon {
    display: none !important;
    pointer-events: none !important;
  }
}
.rc-time-picker-clear {
  margin-right: 20px !important;
}
.client_email_hour_input {
  position: relative;
  svg {
    position: absolute;
    right: 20px;
    top: 5px;
    pointer-events: none;
    cursor: pointer;
  }
}
.multi-select {
  width: 100%;
}
.rmsc .options {
  max-height: 80% !important;
}
.panel-content {
  height: 76vh !important;
  @media (max-width: 1500px) {
    height: 68vh !important;
  }
}

.panel-content .options {
  height: 76vh !important;
  overflow-y: scroll !important;
  @media (max-width: 1500px) {
    height: 68vh !important;
  }
}

.rmsc .dropdown-heading {
  display: none !important;
}
.rmsc .dropdown-content {
  position: relative !important;
}
.rmsc .dropdown-container {
  border: none !important;
}
.rmsc .dropdown-content:focus {
  outline: none !important;
  border: none !important;
}
.rmsc .dropdown-container:focus-within {
  box-shadow: none !important;
  border-color: none !important;
}
.rc-time-picker-input {
  // height: 45px;
  border: none !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #d9d9d9 !important;
}
.daysofweek_svg svg {
  width: 16px;
  height: 16px;
}
.current_agent {
  width: 50px;
  height: 50px;
}

.current_agent svg {
  width: 20px;
  height: 20px;
}

.news_source_check_table {
  .container {
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
  }
  table {
    width: 100%;
  }
  h1 {
    font-size: 1.5em;
  }

  /* TABLE STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .table-wrapper {
    overflow-x: auto;
    height: 400px;
  }

  .table-wrapper::-webkit-scrollbar {
    height: 5px;
  }

  .table-wrapper::-webkit-scrollbar-thumb {
    background: #1b4965;
    border-radius: 40px;
  }

  .table-wrapper::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 40px;
  }

  .table-wrapper table {
    // margin: 50px 0 20px;
    border-collapse: collapse;
    text-align: center;
  }

  .table-wrapper table th,
  .table-wrapper table td {
    padding: 10px;
    min-width: 75px;
  }
  thead tr {
    text-align: left;
  }
  tbody tr {
    text-align: left;
  }
  .table-wrapper table th {
    color: #fff;
    background: #1b4965;
  }

  .table-wrapper table tbody tr:nth-of-type(even) > * {
    background: #edf2f4;
  }

  .table-wrapper table td:first-child {
    // display: grid;
    // grid-template-columns: 25px 1fr;
    // grid-gap: 10px;
    text-align: left;
  }

  .table-credits {
    font-size: 12px;
    margin-top: 10px;
  }

  /* FOOTER STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .page-footer {
    position: fixed;
    right: 0;
    bottom: 50px;
    display: flex;
    align-items: center;
    padding: 5px;
    z-index: 1;
    background: #fff;
  }
}
.load_data_info {
  font-size: 18px;
  margin-top: 30px;
}

// .check_info_spinner{
//   .MuiCircularProgress-svg{
//     width: 60px !important;
//     height: 60px !important;
//   }
// }

.handleGoBack {
  font-weight: 500;
  font-size: 18px;
  border-radius: 5px;
}

.file-preview {
  margin-top: 20px;
  width: max-content;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 8px;
}
th,
td {
  font-size: 13px !important;
}
.MuiTableBody-root .MuiTableCell-root {
  padding: 2px !important;
  padding-left: 6px !important;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
}
.btn.btn-text-info {
  color: #010066 !important;
}

// .btn-hover-info{
// background-color:#010066  !important;
// }
.btn.btn-hover-info:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-hover-info:focus:not(.btn-text),
.btn.btn-hover-info.focus:not(.btn-text) {
  color: #ffffff !important;
  background-color: #010066 !important;
  border-color: #010066 !important;
}
.brand-dark .brand {
  
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background-color: #fff !important;
}
.brand .brand-logo img {
  width: 120px !important;
  object-fit: contain !important;
}
.aside-menu {
  background-color: #010066 !important;
}
.aside {
  background-color: #010066 !important;
}

.MuiBadge-dot {
  top: 50% !important;
  right: unset !important;
  left: 6px;
  top: 0px !important;
}

.notification_body_scroll {
  height: 450px;
  overflow: hidden;
  overflow-y: scroll;
}
.see_all {
  color: red;
  font-size: 14px;
  margin-left: -15px;
  text-decoration: underline;
}

.MuiBadge-badge {
  color: #fff !important;
  background-color: red !important;
}
.notification_icon svg {
  width: 24px;
  height: 24px;
}
.symbol-label {
  cursor: pointer !important;
}
.badge-animate {
  transition: transform 0.3s ease-in-out;
}

.badge-animate.scale {
  transform: scale(1.2);
}
.input_wrapper {
  position: relative;
  button {
    position: absolute;
    right: 10px;
    bottom: 20px;
    background: none;
  }
  svg {
    width: 20px;
    height: 20px;
  }
}
.extarnal_link_wrap {
  position: relative;
  p {
    padding: 0px !important;
    margin: 0px !important;
    margin-left: 20px !important;
  }
}
.extarnal_link svg {
  position: absolute;
  top: 10px;
  left: -10px;
  width: 20px;
  height: 20px;
  margin-left: 12px;
  cursor: pointer;
}
#datetime-local {
  width: 100%;
}
.delete-selected {
  svg {
    fill: red !important;
  }
}
.MuiTableCell-stickyHeader {
  position: unset !important;
}
.fixed_cell {
  position: sticky !important;
  z-index: 3;
}
.checkbox_cell {
  position: fixed !important;
  left: unset !important;
  top: unset !important;
  padding: 2px !important;
  height: 34px;
  display: flex !important;
  width: 49px !important;
  z-index: 99 !important;
  justify-content: center;
  span {
    padding: 6px !important;
  }
}
.fixed-row-cell {
  position: sticky;
  height: 39px;
  background: #fff;
  left: 0px;
  z-index: 9;
}
.MuiTextField-root {
  width: 100% !important;
}
#datetime-local {
  border: none !important;
}
.date_wrapper {
  position: relative;
  .date_text_input {
    position: absolute;
    width: 120px !important;
    top: 16px;
    left: 0px;
    z-index: 3;
    background: #fff;
  }
}
.MuiTablePagination-actions {
  display: none !important;
}
.reset_wrapper {
  background: #f3f6f9;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  &:hover {
    background: #010066;
    svg {
      path {
        stroke: #fff;
      }
    }
  }

  svg {
    width: 18px;
    height: 18px;
    path {
      stroke: #010066;
    }
  }
}

.chart_wrapper {
  position: relative;
  width: 200px;
  height: 250px;
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-right: 30px;
  .chart_label {
    position: absolute;
    top: 0px;
  }
}
.Select_chart_type {
  width: 400px !important;
}
.Chart_type_selector {
  width: 250px !important;
}

li.menu-item {
  font-family: "Poppins" !important;
}
.check_url {
  svg {
    width: 16px;
    height: 16px;
  }
}
.check_url_modal .modal-content {
  height: 280px !important;
  .modal-body {
    min-height: 100px !important;
  }
}

// .MuiPaper-root{
//   width: 100% !important;
// }
.treeView {
  width: 100%;
}
.treeView .MuiTablePagination-root{
  display: none !important;
}